import { find, findAll } from "../utils/dom";


const Content = () => {
	const footer = find('[js-footer]');
	if (!footer) return;

	function remToPx(value) {
		return 16 * parseFloat(value);
	}

	function heightDinamico() {
		const heightDinamico = findAll('[js-height-dinamico]');

		const rootStyles = getComputedStyle(document.documentElement);

		const navFixed = rootStyles.getPropertyValue('--nav-fixed').trim().replace('rem', '');
		const hfooter = rootStyles.getPropertyValue('--footer').trim().replace('rem', '');

		heightDinamico?.forEach((item) => {
			const heightItem = item.offsetHeight || item.clientHeight;
			const subtracaoExtra = parseFloat(item.getAttribute('js-height-dinamico') || 0);

			const novoHeightItem = ((heightItem * remToPx(hfooter)) / remToPx(navFixed)) - subtracaoExtra;

			item.style.height = `${novoHeightItem}px`;

			//console.log(novoHeightItem)


		})
	}

	document.addEventListener("DOMContentLoaded", () => {
		// heightDinamico();
	})
}

export default Content;