import { find } from "../utils/dom";

const Pesquisa = () => {
    const contentPesquisa = find('[js-content-pesquisa]');
    const inputPesquisa = find('[js-input-pesquisa]');

    if (!contentPesquisa) return;

    contentPesquisa.addEventListener("mouseover", (e) => {
        inputPesquisa.classList.remove("invisibled");
    })

    contentPesquisa.addEventListener("mouseout", (e) => {
        inputPesquisa.classList.add("invisibled");
    })
}

export default Pesquisa;