import { find } from "../utils/dom";
//import contatoForm from "../modules/contato";

const PageContato = () => {
	const page = find(`[js-page="contato"]`);
	if (!page) return;

	//contatoForm();
}

export default PageContato;