import { find } from "../utils/dom";

const PageSingleAcervo = () => {
	const page = find(`[js-page='single_acervo_digital']`);
	if (!page) return;

	function handleCompartilhar() {
		const btCompartilhar = find('[js-compartilhar]');
		if (!btCompartilhar) return;

		btCompartilhar.addEventListener("click", (e) => {
			e.preventDefault();

			const animationCopiado = find('.Custom-Copiado');

			const URL = window.location.href;
			navigator.clipboard.writeText(URL);

			e.target.style.pointerEvents = "none";
			animationCopiado?.classList.add('is_animado');

			setTimeout(() => {
				e.target.style.pointerEvents = "unset";
				animationCopiado?.classList.remove('is_animado');
			}, 2000);


		})
	}

	handleCompartilhar();
}

export default PageSingleAcervo;