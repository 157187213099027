import { find, findAll } from "../utils/dom";

const SubMenu = () => {
	const Menu = find('[js-sub-menu]');
	if (!Menu) return;



	function resetOutline() {
		const itensA = Menu.querySelectorAll('a');
		itensA?.forEach((item) => {
			item.classList.remove('is_underline');
		})
	}

	const Sections = findAll('section');
	const observer = new IntersectionObserver((entries, observe) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				const idSecaoAtual = entry.target.id;

				const itemMenu = Menu.querySelector(`a[href='#${idSecaoAtual}']`);
				const is_blue = entry.target.getAttribute('js-blue');
				const subMenuItem = itemMenu?.nextElementSibling;

				resetOutline();
				if (itemMenu) {
					itemMenu.classList.add("is_underline");

				}

				if (is_blue) {
					Menu.classList.add('is_blue');
				} else {
					Menu.classList.remove('is_blue');
				}

				if (subMenuItem) {
					subMenuItem.classList.add('is_open');
				} else {
					find('[js-submenu-itens]').classList.remove('is_open')
				}
			}
		})
	}, {
		threshold: .3,
	})


	Sections?.forEach((section) => {
		observer.observe(section)
	});


	window.addEventListener('scroll', () => {
		const scrollAtual = window.scrollY;

		if (scrollAtual > 180) {
			Menu.classList.add('is_scroll');
		}
		else {
			Menu.classList.remove('is_scroll');
		}
	})
}

export default SubMenu;