import axios from 'axios'
import Toastify from 'toastify-js'
import "toastify-js/src/toastify.css"

export default function contatoForm() {
	const showToastify = (message, opts) => {
		Toastify({
			duration: 3000,
			position: "center",
			style: { background: "rgba(193, 255, 129)", fontSize: "18px", color: "rgba(45, 71, 120, 1)" },
			text: message,
			...opts
		}).showToast();
	}

	const btnFormContato = document.querySelectorAll('.btn-contact')
	const formContato = document.querySelector('#enter-in-contact')


	formContato?.addEventListener("submit", (e) => {
		e.preventDefault();
		disabledBtn()

		const form = new FormData(e.target);

		const form_data = {
			nome: form.get('nome'),
			email: form.get('email'),
			assunto: form.get('assunto'),
			mensagem: form.get('mensagem')
		}

		const url = "/fale-conosco/"
		axios.post(url, form_data)
			.then((response) => showMessage(response))
			.catch((response) => showMessage(response));
	})


	const formNewsLetter = document.querySelector('[js-form-newsletter]');



	formNewsLetter?.addEventListener("submit", (e) => {
		e.preventDefault();
		disabledBtn();

		const form = new FormData(e.target);

		const form_data = {
			email: form.get('receba-news'),
		}

		const url = "/newsletter/"
		axios.post(url, form_data)
			.then((response) => showMessage(response))
			.catch((response) => showMessage(response));

	})


	/*
		if (btnFormContato) {
		
			btnFormContato.forEach((btn) => {
		
				formContato && btn.addEventListener('click', function (event) {
					event.preventDefault();
					disabledBtn()
		
					const form = new FormData(event);
					console.log(form)
		
					const form_data = {
						nome: `${primeiro_nome.value}`,
						email: email.value,
						mensagem: box_text.value,
						area_interesse: area_interesse.value,
						telefone: telefone.value,
						//assunto: assunto.value,
					}
		
					const url = "/fale-conosco/"
					axios.post(url, form_data)
						.then((response) => showMessage(response))
						.catch((response) => showMessage(response));
	});
		})
	} */

	const Languagem = document.querySelector('[lang]').lang;

	function showMessage(response) {
		const isSuccess = response.data && response.data.message;
		// const message = response.data ? response.data.message : response.message

		const Languagem = document.querySelector('[lang]').lang;
		let message = ''
		if (Languagem == 'en') {
			message = isSuccess ? 'Message sent successfully! Thank you!' : 'Oops, something went wrong! Try again!'
		} else {
			message = isSuccess ? 'Mensagem enviada com sucesso! Obrigado!' : 'Oops, aconteceu algo de errado! Tente de novo!'
		}

		const backgroundColor = isSuccess ? "rgba(193, 255, 129)" : "rgba(233, 44, 44)";
		const textColor = isSuccess ? "rgba(45, 71, 120, 1)" : "white";

		showToastify(message, {
			style: {
				background: backgroundColor,
				color: textColor,
				fontSize: "18px",
				borderRadius: "8px"
			},
		});
		formContato?.reset()
		formNewsLetter?.reset()
		setTimeout(enabledBtn, 1000)
	}

	function disabledBtn() {
		btnFormContato.forEach((btn) => {
			btn.disabled = true
			btn.classList.add("disabled")
		})
	}

	function enabledBtn() {
		btnFormContato.forEach((btn) => {
			btn.disabled = false
			btn.classList.remove("disabled")
		})
	}
}