import Swiper from "swiper"
import { find } from "utils/dom"
import SplitType from 'split-type'
import { findAll } from "../utils/dom"

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return

	const { md } = GLOBAL.screen
	splitTexts()

	if (!md) {

		let SwiperSectionsHome = new Swiper(".swiper-section-home", {
			direction: "vertical",
			slidesPerView: 1,
			spaceBetween: 0,
			speed: 600,
			effect: 'fade',
			loop: true,
			autoplay: {
				delay: 4500,
				disableOnInteraction: true,
			},
			mousewheel: {
				forceToAxis: true,
				sensitivity: 1,
				thresholdDelta: 10,
				releaseOnEdges: false,
			},
		});


		let SwiperMenuHome = new Swiper(".swiper-menu-home", {
			slidesPerView: 1,
			effect: 'fade',
			spaceBetween: 0,
			speed: 600,
			loop: true,

			pagination: {
				el: ".swiper-menu-home .swiper-pagination",
				clickable: true,
			},

		})


		SwiperSectionsHome.controller.control = SwiperMenuHome;
		SwiperMenuHome.controller.control = SwiperSectionsHome;
	}


}


function splitTexts() {
	const texts = findAll('.split-text')

	const textSplited = texts.map(text => SplitType.create(text, { types: 'words' }))
	console.log(textSplited[0]);

	textSplited.forEach(({ words, elements }) => {
		const el = elements.at()
		if (!el) return
		const lastWord = words?.at(-1)
		lastWord?.classList.add('!inline-flex', 'items-center', 'flex-nowrap', 'gap-16')
		lastWord?.insertAdjacentHTML("beforeend",
			`
		<button  class='transition-transform hover:translate-x-8'>
			<svg class="size-[2.5rem] text-white xl2:scale-[.8]">
				<use xlink:href="#icon_arrow_left_2"></use>
			</svg>
		</button>
		`)
	})
}
