import { find, findAll } from "../utils/dom";


const configUserAway = () => {



    function handleBTUserAway(userway) {
        const button = find('[js-bt-userway]');
        if (!button) return;

        button.addEventListener("click", () => {
            userway.widgetOpen()
        })
    }

    function changeLanguage(userway) {
        const buttonLang = findAll('[js-lang]');
        if (!buttonLang) return;

        buttonLang.forEach((bt) => {
            bt.addEventListener("click", (e) => {
                const sigla = e.target.getAttribute('js-lang');
                userway.changeWidgetLanguage(sigla);
            })
        })

    }

    document.addEventListener("userway:render_completed", function (event) {
        var userway = event.detail.userWayInstance;
        if (userway) {
            //userway.classList.add('custom-icon-class');
            handleBTUserAway(userway)
            changeLanguage(userway)
            console.log(userway)
        }
    });

}



export default configUserAway;