import { find, findAll } from "../utils/dom";
import Swiper from "swiper";


const PageAArtista = () => {
	const page = find("[js-page='a_artista']");
	if (!page) return;


	/*
		const swiperItemCronologia = new Swiper(".swiper-item-cronologia", {
			spaceBetween: 4,
			slidesPerView: 20,
			freeMode: true,
			watchSlidesProgress: true,
		})
	
		const swiperCronologia = new Swiper(".swiper-cronologia", {
			spaceBetween: 10,
			mousewheel: false,
			allowTouchMove: false,
			thumbs: {
				swiper: swiperItemCronologia,
			},
		})*/



	let SwiperProgressoAnos = new Swiper(".swiper-progresso-ano", {
		slidesPerView: 3,
		spaceBetween: 50,
		speed: 600,
		autoplay: {
			delay: 4500,
		}
	})


	/*
	const MENUFIXO = find('[js-menu-fixo-a-artista]');

	let SwiperSectionsArtista = new Swiper(".swiper-section-artista", {
		direction: "vertical",
		slidesPerView: 1,
		spaceBetween: 0,
		speed: 600,
		mousewheel: {
			forceToAxis: true,
			sensitivity: 1,
			thresholdDelta: 10,
			releaseOnEdges: false,
		},
	});

	SwiperSectionsArtista.on('slideChange', (e) => {

		const slideAtivo = e.slides[e.activeIndex]
		if (slideAtivo.dataset.menuBlue) {
			MENUFIXO?.classList.add("is_blue");
		} else {
			MENUFIXO?.classList.remove("is_blue");
		}
		
		if (e.realIndex > 0) {
			MENUFIXO?.classList.add('is_scroll');
		} else {
			MENUFIXO?.classList.remove('is_scroll');
		}


		//console.log(e)
	})

	let SwiperProgressoAnos = new Swiper(".swiper-progresso-ano", {
		slidesPerView: 3,
		spaceBetween: 50,
		speed: 600,
	})

*/
	/*
	
		function handleMenu() {
			const menu = find('[js-menu-a-artista]');
			const itensMenu = menu.querySelectorAll('a');
	
			if (!itensMenu) return;
	
			itensMenu.forEach((item) => {
				item.addEventListener("click", (e) => {
					e.preventDefault();
	
					const idSection = e.target.getAttribute('href');
					const itensSwiperSection = findAll('.swiper-section-artista .swiper-slide');
	
					itensSwiperSection?.forEach((itemSwiper, index) => {
						if (itemSwiper.querySelector(`${idSection}`)) {
							SwiperSectionsArtista.slideTo(index);
						}
					})
	
	
				})
			})
		}
	
		handleMenu();
	*/

}

export default PageAArtista;