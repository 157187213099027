import { findAll, find } from "../utils/dom";

const PageApoio = () => {
	const page = find(`[js-page="apoio"]`);
	if (!page) return;



	function handleDropdows() {
		const dropdows = findAll("[js-dropdown]");
		if (!dropdows) return;


		dropdows.forEach((drop) => {
			drop.addEventListener("click", (e) => {
				const dropId = e.target.getAttribute('dropdown');
				const conteudoDrop = find(`[content-dropdown='${dropId}']`);
				const IconeDrop = e.target.querySelector(".icone_open");

				conteudoDrop?.classList.toggle("is_open");
				if (conteudoDrop?.classList.contains("is_open")) {
					IconeDrop?.classList.add("is_open");
				} else {
					IconeDrop?.classList.remove("is_open");
				}
			})
		})
	}

	handleDropdows();

}

export default PageApoio;