import { find } from "../utils/dom";
import Swiper from "swiper";

const PageInstituto = () => {

	const page = find("[js-page='instituto']");
	if (!page) return;


	const MENUFIXO = find('[js-menu-fixo-instituto]');


	window.addEventListener("scroll", () => {
		let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
		if (scrollTop > 90) {
			MENUFIXO?.classList.add('is_scroll');
		} else {
			MENUFIXO?.classList.remove('is_scroll');
		}
	})


	const SwiperCreditos = new Swiper(".swiper-creditos", {
		slidesPerView: 1,
		speed: 600,
		pagination: {
			clickable: true,
			el: ".swiper-pagination",
		},
		breakpoints: {
			600: {
				slidesPerView: 2,
			},
			1200: {
				slidesPerView: 3,
			}
		}
	})

	/*let SwiperSectionsInstituto = new Swiper(".swiper-section-instituto", {
		direction: "vertical",
		slidesPerView: 1,
		spaceBetween: 0,
		speed: 600,
		mousewheel: {
			forceToAxis: true,
			sensitivity: 1,
			thresholdDelta: 10,
			releaseOnEdges: false,
		},
	});

	SwiperSectionsInstituto.on('slideChange', (e) => {

		const slideAtivo = e.slides[e.activeIndex]
		if (slideAtivo.dataset.menuBlue) {
			MENUFIXO?.classList.add("is_blue");
		} else {
			MENUFIXO?.classList.remove("is_blue");
		}

		if (e.realIndex > 0) {
			MENUFIXO?.classList.add('is_scroll');
		} else {
			MENUFIXO?.classList.remove('is_scroll');
		}


		//console.log(e)
	})*/


	/*
	function handleMenu() {
		const menu = find('[js-menu-instituto]');
		const itensMenu = menu.querySelectorAll('a');

		if (!itensMenu) return;

		itensMenu.forEach((item) => {
			item.addEventListener("click", (e) => {
				e.preventDefault();

				const idSection = e.target.getAttribute('href');
				const itensSwiperSection = findAll('.swiper-section-instituto .swiper-slide');

				itensSwiperSection?.forEach((itemSwiper, index) => {
					if (itemSwiper.querySelector(`${idSection}`)) {
						SwiperSectionsArtista.slideTo(index);
					}
				})


			})
		})
	}

	handleMenu();*/
}

export default PageInstituto;