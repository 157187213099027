import SmoothScroll from "smooth-scroll";

const ScrollSmooth = () => {

	const rootStyles = getComputedStyle(document.documentElement);
	const navFixed = rootStyles.getPropertyValue('--nav-fixed').trim().replace('rem', '');

	const OffSet = (parseFloat(navFixed) * 16) - 16;
	console.log(navFixed)
	const scroll = new SmoothScroll('a[href*="#"]', {
		speed: 600,
		offset: OffSet,
		easing: 'easeInOutCubic'
	});
}

export default ScrollSmooth;